/* SimpleTagCloud.css */
.tag-cloud-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    perspective: 1200px; /* Mejorar la profundidad */
    background-color: #232333; /* Fondo oscuro principal */
    padding: 50px 0; /* Espaciado superior e inferior */
  }
  
  .tag-cloud {
    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    animation: rotateCloud 12s infinite linear;
  }
  
  .tag {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff; /* Texto blanco por defecto */
    padding: 8px 16px;
    border-radius: 10px;
    background-color: #333; /* Color de fondo oscuro de las etiquetas */
    margin: 10px;
    transition: transform 0.5s ease, color 0.3s ease, background-color 0.3s ease;
    will-change: transform, color, background-color;
    opacity: 0.9;
    transform-origin: center center; /* Centrar el punto de rotación */
  }
  
  .tag:hover {
    transform: scale(1.2);
    color: #232333; /* Color oscuro de fondo al pasar el ratón */
    background-color: #dd7682; /* Color resaltado para el fondo al pasar el ratón */
  }
  
  @keyframes rotateCloud {
    0% {
      transform: rotateY(0deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }
  
  /* Ajuste de las etiquetas para que siempre se vean bien */
  .tag:nth-child(1) {
    transform: rotateY(0deg) translateZ(200px);
  }
  .tag:nth-child(2) {
    transform: rotateY(30deg) translateZ(200px);
  }
  .tag:nth-child(3) {
    transform: rotateY(60deg) translateZ(200px);
  }
  .tag:nth-child(4) {
    transform: rotateY(90deg) translateZ(200px);
  }
  .tag:nth-child(5) {
    transform: rotateY(120deg) translateZ(200px);
  }
  .tag:nth-child(6) {
    transform: rotateY(150deg) translateZ(200px);
  }
  .tag:nth-child(7) {
    transform: rotateY(180deg) translateZ(200px);
  }
  .tag:nth-child(8) {
    transform: rotateY(210deg) translateZ(200px);
  }
  .tag:nth-child(9) {
    transform: rotateY(240deg) translateZ(200px);
  }
  .tag:nth-child(10) {
    transform: rotateY(270deg) translateZ(200px);
  }
  .tag:nth-child(11) {
    transform: rotateY(300deg) translateZ(200px);
  }
  .tag:nth-child(12) {
    transform: rotateY(330deg) translateZ(200px);
  }
  
  /* Mantener las etiquetas mirando siempre hacia adelante */
  .tag-cloud {
    animation: rotateCloud 12s infinite linear;
    transform: rotateY(0deg); /* Establecer que la rotación principal siempre esté en el mismo eje */
  }
  
  