
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100..900;1,100..900&display=swap');

/* Projects Gallery Styles */
.projects-gallery {
  z-index: 10;
  position: relative;
  overflow: hidden;
  padding: 50px 10%;
  background-color: #232333;
  color: white;
  text-align: center;
  min-height: 100vh;
}

.projects-gallery h1 {
  font-size: 40px;
  font-family: 'Bitter';
}

/* .projects-gallery p {
  font-size: 40px;
  font-family: 'Poppins';
} */


.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
  justify-items: center;
  margin-top: 20px;
  margin-bottom: 10%;
}

.project-card {
  background-color: #333;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.project-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.project-card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: 'Bitter', sans-serif;
}

.project-card p {
  font-size: 1rem;
  color: #bbb;
  font-family: 'Poppins', sans-serif;
  line-height: 1.8;
}

.tech-color {
  color: #ff8591;
  font-weight: bold;
}

/* Responsive Styles */
@media screen and (max-width: 760px) {
  .projects-gallery {
    padding-bottom: 15%;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Waves Styling */
.wave {
  position: absolute;
  width: 100%;
  height: 100px;
  background: url(wave.png);
  background-size: 1000px 100px;
  bottom: 0;
  left: 0;
}

.wave1 {
  animation: animate 30s linear infinite;
  z-index: 1;
  opacity: 1;
  animation-delay: 0s;
}

.wave2 {
  animation: animate2 15s linear infinite;
  z-index: 2;
  opacity: 0.5;
  animation-delay: -5s;
}

.wave3 {
  animation: animate 30s linear infinite;
  z-index: 3;
  opacity: 0.2;
  animation-delay: -2s;
}

.wave4 {
  animation: animate2 5s linear infinite;
  z-index: 4;
  opacity: 0.7;
  animation-delay: -5s;
}

@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}

@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}
