@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100..900;1,100..900&display=swap');

/* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f9f9f9;
}

/* Contact Section Layout */
.contact-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 60px 20px;
  min-height: 60vh;
}

/* Contact Form Container */
.contact-form {
  width: 100%;
  max-width: 650px;
  padding: 30px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Contact Form Heading */
.contact-form h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Bitter', sans-serif;
}

/* Form Groups (Labels and Inputs) */
.contact-form .form-group {
  margin-bottom: 15px;
  text-align: left;
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  font-size: 1rem;
  color: #666;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

/* Textarea Specific Styling */
.contact-form textarea {
  height: 100px;
  resize: none;
}

/* Submit Button */
button {
  width: 100%;
  background-color: #dd7682;
  color: white;
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Poppins', sans-serif;
}

button:hover {
  background-color: #c25667;
  transform: scale(1.05);
}

/* Responsive Design */

/* Medium Screens (Tablets) */
@media screen and (max-width: 768px) {
  .contact-section {
    padding: 40px 15px;
  }

  .contact-form h2 {
    font-size: 2rem;
  }

  .contact-form {
    padding: 20px;
  }

  button {
    font-size: 1.1rem;
  }
}

/* Small Screens (Mobile Devices) */
@media screen and (max-width: 480px) {
  .contact-form h2 {
    font-size: 1.8rem;
  }

  .contact-form label {
    font-size: 0.9rem;
  }

  button {
    font-size: 1rem;
  }
}
