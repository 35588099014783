
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100..900;1,100..900&display=swap');

/* Particles Background */
#particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #232333;
}

/* Main Content Layout */
.content {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Centered Text Styling */
.centered-text {
  color: white;
  text-align: center;
  z-index: 2;
}

.centered-text h1 {
  font-family: 'Bitter', sans-serif;
  font-size: 3.2rem;
  margin: 0;
}

.centered-text p {
  font-family: 'Bitter', sans-serif;
  font-size: 1.5rem;
  margin: 10px 0 0 0;
}

/* Highlighted Text Styling */
.highlighted-name {
  color: #DD7682;
  font-weight: bold;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

/* Presentation Section Layout */
.presentation-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  position: relative;
  z-index: 2;
  margin: 0 auto;
  background-color: #232333;
  color: white;
  min-height: 100vh;
}

/* Left and Right Presentation Layout */
.presentation-left {
  flex: 1;
  padding-right: 20px;
}

.presentation-right {
  flex: 1;
  padding-left: 20px;
}

/* Contact Section Styling */
.contact-container {
  position: relative;
  z-index: 2;
}
/* Responsive Layout for Small Screens */
@media screen and (max-width: 760px) {
  .presentation-container {
    flex-direction: column;
  }

  .presentation-right {
    display: none; /* Cambié "hidden" por "none" */
  }
  
  .centered-text {
    margin: 20px;
  }

  .centered-text p {
    font-size: 1.3rem;
  }

  .presentation-left {
    max-height: 60vh;
  }

}
